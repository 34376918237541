@use "../../../../variables" as variables;

// Modals
.modal-backdrop {
  background-color: black;
  bottom: 0;
  position: fixed;

  &.in {
    opacity: 0.4;
  }
}

.modal-content {
  box-shadow: none;
}

.modal-footer,
.modal-header {
  border-color: variables.$gainsboro;
}
