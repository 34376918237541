@use "../../../../variables" as variables;
@use "../variables" as app_variables;

.list-bien-photos {
  > li {
    $toolbar-height: 35px;

    position: relative;
    width: 300px;
    height: 300px;
    margin: 0 10px 10px;
    border: 1px solid variables.$green;
    display: inline-block;

    &.placeholder {
      text-align: center;
      padding-top: 139px;
      background-color: app_variables.$lightGrey;
      border-style: dashed;
    }

    &.ui-sortable-placeholder {
      height: 300px;

      &.placeholder:after {
        left: 0;
      }
    }

    > ul.toolbar {
      position: absolute;
      background: rgba(black, 0.3);
      width: 100%;
      height: $toolbar-height;
      text-align: right;
      padding-right: 5px;

      > li {
        padding: 5px 5px;
        display: inline-block;
        color: white;
        font-size: app_variables.$font-size-large;
        width: 25px;

        &.loading {
          width: initial;
        }

        & > *:hover,
        .dropdown-toggle:hover,
        .dropdown-toggle:focus {
          color: variables.$green;
          cursor: pointer;
        }

        &.photo-more-actions {
          button {
            background: none;
          }

          .dropdown-toggle {
            padding: 0;
            box-shadow: none;
          }

          .dropdown-menu {
            color: variables.$primary;
            width: 230px;

            ul li {
              > * {
                padding: 6px 12px;
                height: 32px;
                display: flex;
                gap: .25rem;

                & > * {
                  margin-left: 0;
                  width: 18px;
                  text-align: center;                }

                &:hover,
                &:focus,
                &:active {
                  color: app_variables.$darkPrimary;
                }
              }

              &:not(:first-child) {
                margin-top: 5px;
              }
            }
          }
        }
      }
    }

    /* Ribbon */
    .ribbon {
      background: variables.$greyLight;
      z-index: 1;
      border-radius: 0 5px 5px 0;

      &:before,
      &:after {
        content: "";
        position: absolute;
        left: -9px;
        border-left: 10px solid transparent;
      }

      &:before {
        top: 0;
        border-top: 27px solid variables.$greyLight;
      }

      &:after {
        bottom: 0;
        border-bottom: 27px solid variables.$greyLight;
      }

      span {
        display: block;

        &.ribbon-warning {
          color: variables.$warning;
        }

        &.ribbon-danger {
          color: variables.$danger;
        }
      }
    }

    .ribbon {
      $ribbon-padding: 3px;

      position: absolute;
      top: calc($toolbar-height / 2) + $ribbon-padding;
      padding: $ribbon-padding;
      width: 65px;
      text-align: center;
    }

    > img {
      width: 100%;
      height: 100%;
    }
  }

  &:after {
    clear: both;
  }
}

.table-biens {
  tr {
    &:hover {
      background-color: app_variables.$lightGrey;
    }
  }

  .line {
    .validate {
      color: green;
    }

    .photo {
      width: 70px;
      height: 70px;
    }

    .text {
      max-height: 100px;
    }

    .thin-text {
      max-height: 50px;
    }

    .select {
      label {
        margin: 0;
        padding-left: 20px;
      }
    }

    .col-nom {
      max-width: 350px;

      .ellipsis {
        white-space: initial;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
      }
    }

    .col-adresse {
      max-width: 400px;

      @media (max-width: app_variables.$screen-md-max) {
        max-width: 300px;
      }

      .adresse {
        width: 100%;

        .number {
          max-width: 10%;
        }

        .nom {
          max-width: 90%;
          padding-left: 5px;
        }
      }
    }

    .angle-right {
      a {
        color: variables.$textColor;

        div {
          height: 70px;
          padding-top: 27px;
        }
      }
    }
  }
}

.table-photo-biens {
  .line {
    height: auto;
    padding: 10px;

    .photo-display {
      text-align: center;
      display: inline-block;
      float: left;
      padding-right: 20px;
      margin-bottom: 0;

      .photo {
        width: 200px;
        height: 200px;
      }
    }

    .informations {
      display: inline-block;
      width: calc(100% - 220px);

      .resume {
        line-height: 27px;
      }

      .descriptif {
        max-height: 70px;
        text-align: justify;
      }

      .descriptif.ellipsis {
        white-space: initial;
        display: -webkit-box;
        -webkit-line-clamp: 4;
        -webkit-box-orient: vertical;
      }

      .second-line {
        max-height: 20px;
        overflow: hidden;
        border-bottom: variables.$border-separator;
        padding-bottom: 10px;

        .localisation {
          > * {
            float: left;
            padding-left: 5px;
          }

          p {
            max-height: 20px;
            display: inline-block;
          }

          .adresse {
            max-width: 50%;
          }
        }
      }

      .select {
        margin-top: -10px;

        .clip-check {
          margin-bottom: 0;

          label {
            margin-right: 0;
            padding-left: 20px;

            &:after {
              width: 0;
            }
          }
        }
      }
    }

    @media (max-width: app_variables.$screen-xs-max) {
      .photo-display {
        display: block;
        float: initial;
      }

      .informations {
        width: 100%;
      }
    }
  }
}

.collection-biens {
  .public .thumbnail {
    height: 400px;
  }

  .private .thumbnail {
    height: 430px;
  }

  .content {
    padding-right: 10px;
    float: left;

    .thumbnail {
      width: 300px;
      overflow: hidden;
      box-shadow: 0 4px 8px 0 rgba(black, 0.2);
      transition: 0.3s;

      .content-photo {
        margin-bottom: 10px;
        position: relative;

        .photo-header,
        .photo-price {
          position: absolute;
          width: 100%;
          margin-left: 0;
          margin-right: 0;

          .popover {
            width: 230px;
            height: 150px;
          }
        }

        .photo-header {
          top: 10px;
          left: 10px;
        }

        .photo-price {
          width: 100%;
          bottom: 0;
          left: 0;
          padding-bottom: 8px;
          padding-right: 8px;
          text-align: right;
        }

        .select {
          .checkbox {
            margin-top: 0;

            label {
              margin: -2px;
              padding-left: 20px;

              &:after {
                width: 0;
              }
            }
          }
        }

        .photo {
          width: 290px;
          height: 290px;
        }
      }

      .localisation {
        margin-bottom: 10px;
        min-height: 52px;

        .commune {
          max-height: 25px;
        }
      }

      .owners {
        max-height: 20px;
        margin-bottom: 10px;
      }

      .last-line {
        position: relative;

        > * {
          position: absolute;
          top: 10px;
        }

        .dossier {
          right: 5px;
        }
      }
    }

    .thumbnail:hover {
      box-shadow: 0 8px 16px 0 rgba(black, 0.2);
    }
  }
}

.box-biens {
  .line {
    padding: 10px 10px 0 10px;

    .media {
      display: grid;
      grid-template-columns: 80px auto;

      .media-left,
      .media-body {
        display: block;
      }
    }

    .photo {
      width: 70px;
      height: 70px;
    }

    .informations {
      padding-left: 10px;
      width: auto;

      .resume {
        line-height: 27px;

        esk-estimation-label-valeur,
        esk-location-label-rent {
          margin-right: 3px;
        }

        esk-estimation-label-statut {
          margin-left: 4px;
        }
      }

      .titre {
        max-height: 20px;
      }

      .descriptif {
        max-height: 85px;
        text-align: justify;
      }

      .localisation {
        max-height: 20px;
        overflow: hidden;
        border-bottom: variables.$border-separator;
        padding-bottom: 10px;

        > * {
          float: left;
          padding-left: 5px;
        }

        p {
          max-height: 20px;
          display: inline-block;
        }

        .adresse {
          max-width: 50%;
        }
      }

      .select {
        margin-top: -10px;

        .clip-check {
          margin-bottom: 0;

          label {
            margin-right: 0;
            padding-left: 20px;

            &:after {
              width: 0;
            }
          }
        }
      }
    }
  }

  &.list-group-pimped {
    margin-bottom: 0;
    padding-left: 0;
  }

  .list-pimped {
    border-top: variables.$border-separator;
    background: white;
  }

  .list-pimped:first-child {
    border-top: 0;
  }
}

.edit-collections {
  .row {
    > * {
      padding-right: 10px;
      padding-left: 10px;

      @media (min-width: app_variables.$screen-md-min) and (max-width: app_variables.$screen-md-max) {
        padding-right: 5px;
        padding-left: 5px;
      }
    }
  }

  .legend {
    padding-left: 25px;
    text-align: center;
    font-weight: bold;
    min-height: auto;

    .legend-date {
      text-align: left;
      padding-left: 55px;
    }
  }

  .list-group {
    padding: 0 5px;
    margin-right: -15px;
    margin-bottom: 10px;

    .list-group-item {
      border: 0;
      padding: 10px 5px;
      cursor: default;

      .drag-handler {
        left: 0;
        margin-top: 5px;
      }

      .checkbox {
        position: absolute;
        left: 0;
        margin-top: 5px;
      }

      .item {
        padding-left: 20px;

        .form-control {
          box-shadow: unset;
          border: 0;
          border-bottom: variables.$border-separator;
          padding: 7px 5px;
          height: 32px;
          -webkit-border-radius: 0;
          -moz-border-radius: 0;
          border-radius: 0;
        }

        .num-group {
          .form-control {
            padding-right: 17px;

            &.large {
              padding-right: 25px;

              @media (min-width: app_variables.$screen-md-min) and (max-width: app_variables.$screen-md-max) {
                padding-right: 30px;
              }
            }

            @media (max-width: app_variables.$screen-xs-max) {
              padding-right: 5px;

              &.large {
                padding-right: 5px;
              }
            }
          }

          .num-icon {
            position: absolute;
            top: 7px;
            right: 15px;
            color: app_variables.$textDark;

            @media (max-width: app_variables.$screen-xs-max) {
              display: none;
            }
          }
        }

        .other {
          > * {
            width: 25px;
          }

          button {
            padding-left: 5px;
            padding-right: 5px;

            &.has-error {
              color: variables.$danger;

              i.error {
                position: absolute;
                top: 0;
                right: 2px;
                font-size: app_variables.$font-size-extra-small;
              }
            }
          }

          .popover {
            width: 400px;
            max-width: 400px;

            label {
              font-weight: bold;
            }
          }
        }

        .error {
          font-weight: 200;
        }
      }
    }

    > li:focus,
    li:hover {
      background-color: transparent;
      cursor: default;
    }
  }

  &.cadastres {
    border-left: solid 1px app_variables.$lightGrey;
    padding-right: 0;

    ul {
      li {
        .item {
          .other {
            .popover {
              .prefixe {
                opacity: 0.5;
              }
            }
          }
        }
      }
    }
  }

  @media (max-width: app_variables.$screen-xs-max) {
    padding-left: 0;
  }
}

.display-photos {
  .marianne {
    max-width: 20%;
  }

  .main {
    padding: 1px 15px;

    .photo {
      max-width: 100%;
      max-height: 400px;
    }
  }

  ul {
    margin-bottom: 0;

    @media (max-width: app_variables.$screen-xs-max) {
      .one-column {
        width: 33%;
      }

      .two-columns {
        width: 25%;
      }

      .list-thumbnail {
        padding: 5px 0 0 5px;
      }
    }

    @media (min-width: app_variables.$screen-sm-min) {
      .one-column {
        width: 100%;
      }

      .two-columns {
        width: 50%;
      }

      .list-thumbnail {
        padding: 1px 4px 3px 0;
      }
    }

    .list-thumbnail {
      margin: 0;
      position: relative;

      .photo {
        display: inline;
        width: 100%;
      }

      .sail {
        height: 100%;
        width: 100%;
        position: absolute;
        top: 0;
        padding: 1px 4px 3px 0;
        font-size: app_variables.$font-size-large;

        .photos-plus {
          background-color: rgba(black, 0.5);
          height: 100%;
          width: 100%;
          color: white;
          font-size: app_variables.$multiply-2;
          display: flex;

          p {
            margin: auto;
          }
        }
      }
    }
  }
}

.modal-display-photos {
  width: 100%;
  height: 100%;
  margin: 0;

  .modal-content {
    width: 100%;
    height: 100%;
    background-color: rgba(black, 0.8);
    border: 0;
    position: absolute;

    .main {
      width: 100%;
      height: 100%;

      .photo {
        max-width: 100%;
        max-height: 100%;
        position: absolute;
        margin: auto;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
      }
    }

    .photo-number {
      position: fixed;
      top: 10px;
      left: 10px;
    }

    .arrow {
      position: fixed;
      top: calc(50% - 30px);
      background-color: transparent;
      border: none;
      color: white;
      font-size: app_variables.$font-size-mega-large;
    }

    .left {
      left: 20px;
    }

    .right {
      right: 20px;
    }

    .btn-danger {
      position: fixed;
      top: 10px;
      right: 10px;
    }
  }
}

.diffusions {
  h3 {
    margin-top: 15px;
  }

  .panel {
    .panel-heading {
      position: relative;
    }

    .collapse-toggler {
      color: variables.$primary;
      cursor: pointer;
      position: absolute;
      top: 0;
      right: 0;
      padding: 10px;
    }

    &.collapsed {
      .panel-body {
        display: none;
      }

      .panel-footer {
        border-top: none;
      }
    }
  }

  .list-diffusion {
    .diffusion-VI {
      text-align: justify;
      color: app_variables.$textDark;

      .VI-administration {
        margin-bottom: 0;
      }

      .VI-actions {
        padding-left: 5px;

        .VI-children {
          padding-left: 10px;
        }
      }
    }

    .panel-heading {
      height: 60px;

      .media {
        height: 100%;

        .media-left {
          height: 100%;

          .media-object {
            height: 100%;

            img {
              max-height: 100%;
              max-width: 100%;
            }
          }
        }

        .media-body {
          .media-heading {
            margin-top: 2px;

            a + .btn-null {
              padding-top: 0;
              padding-bottom: 0;

              & + .popover {
                max-width: 300px;
                width: 300px;
              }
            }
          }
        }
      }
    }

    .panel-body {
      .diffusion-VI {
        margin-top: 10px;
      }
    }
  }
}

.template-item {
  cursor: pointer;
  margin: 10px 0;
  padding: 5px;

  &.selected {
    box-shadow: 0 0 8px 4px app_variables.$info;
  }
}

.modal-affichette {
  .template-item {
    text-align: center;

    img {
      display: inline-block;
      width: 70%;
    }
  }
}

.modal-network {
  .modal-body {
    padding: 0;

    .list-photos {
      padding: 0;

      .photo {
        float: left;
        height: 140px;
        width: 140px;
        margin-right: 15px;
        margin-bottom: 15px;
        border: 1px solid variables.$green;

        > img {
          width: 100%;
          opacity: 1;

          &.not-diffuse {
            opacity: 0.2;
          }
        }
      }
    }
  }
}

.wallet {
  .diffusions {
    .only-mine {
      padding-left: 8px;
    }

    table {
      thead {
        .diffusions-information {
          th {
            border-bottom: 0;
          }
        }

        .diffusions-biens-sended {
          esk-passerelle-contrat {
            font-weight: initial;
          }

          .clip-check input[type="checkbox"]:checked + label:before {
            border-color: variables.$primary;
          }
        }
      }

      .lastColumn {
        border: 0;
      }

      .dossier {
        cursor: pointer;

        .reference {
          margin: 0 10px 0 0;
          width: 100px;
          float: left;
        }

        .photo {
          width: 150px;
          height: 150px;
        }
      }

      td {
        &.select {
          .checkbox {
            margin: 0;
          }
        }

        &.diffusion {
          padding: 0;
        }
      }

      button {
        border: 0;
        background-color: transparent;

        span {
          .darker {
            color: app_variables.$dark20Success;
          }
        }
      }
    }
  }
}

@media (max-width: app_variables.$screen-sm-max) {
  .collection-biens {
    .content {
      padding-right: 8px;

      .thumbnail {
        width: 290px;
      }
    }
  }
}

app-bien-edit-localisation {
  .popover {
    max-width: 280px;
  }

  .bien-commune-edit-help {
    float: right;
  }

  .bien-commune-blocked {
    float: left;
    margin: 15px 15px 0;
    font-weight: bold;
    font-size: variables.$font-size-extra-large;
  }

  .bien-codepostal-edit-help {
    position: absolute;
    top: -8px;
    right: -10px;

    .popover {
      width: 200px;
    }
  }
}

@media (max-width: app_variables.$screen-xs-max) {
  .contact-search-container {
    margin-top: 15px;
  }
}

.labels-line-height-25 {
  line-height: 25px;
}

.header-labels {
  .header-labels-main {
    float: left;

    > * {
      margin-right: 6px;
    }
  }

  .header-labels-localisation {
    max-width: 70%;
    margin-left: -4px;
    cursor: pointer;

    a {
      padding: 0;
    }

    .popover {
      width: 400px;
      max-width: 400px;
    }
  }
}

.dossier-popover {
  min-width: 800px;
  max-width: 800px;

  @media (max-width: app_variables.$screen-md-max) {
    min-width: 600px;
    max-width: 600px;
  }

  @media (max-width: app_variables.$screen-sm-max) {
    min-width: 500px;
    max-width: 500px;
  }

  @media (max-width: app_variables.$screen-xs-max) {
    min-width: 60%;
    max-width: 60%;
  }

  h4 {
    color: variables.$primary;
  }

  .info-line {
    width: 100%;
    position: relative;
    margin-bottom: 5px;

    > div {
      display: inline-block;
    }

    .prices-info {
      width: 100%;

      .price-group {
        margin-top: 5px;
      }

      .price-text {
        margin-top: 0;
        color: variables.$green;
      }
    }
  }

  hr {
    margin-top: 5px;
    margin-bottom: 10px;
  }
}

.virtual-visit {
  .virtual-visit-logo {
    img {
      width: 100%;
      max-width: 425px;
    }
  }

  .virtual-visit-video {
    .video {
      position: relative;
      height: 420px;
      width: 560px;
      margin: auto;

      @media (max-width: app_variables.$screen-sm-max) {
        height: 60vw;
        width: 80vw;
      }

      iframe {
        height: 100%;
        width: 100%;
      }

      a {
        position: absolute;
        bottom: -25px;
        right: 0;
      }
    }
  }
}

app-bien-edit-description {
  .type {
    .form-group {
      margin-bottom: 5px;

      label {
        margin: 0;
      }

      .radio-inline {
        /* @todo supprimer les "!important", faire le tour de l'application */
        margin: 0 !important;
      }
    }
  }

  .descriptifs {
    .tabbable {
      .tab-content {
        .tab-pane {
          padding: 0;

          textarea {
            min-height: 140px;
          }
        }
      }
    }
  }
}

.has-documents {
  .documents {
    color: app_variables.$dark15Green;
  }
}

esk-biens-wallet-display-photo {
  cursor: pointer;
}

.share-documents {
  .modal-body {
    .share-add-contact {
      .add-contact-search {
        padding: 19px 0;

        @media (max-width: app_variables.$screen-xs-max) {
          padding: 0;

          button {
            margin-top: 20px;
          }
        }
      }

      .add-contact-new {
        @media (max-width: app_variables.$screen-sm-max) {
          form {
            .new-fields {
              .row {
                .col-xs-4 {
                  padding-right: 0;
                }
              }
            }
          }
        }

        button {
          margin-top: 19px;
        }
      }
    }
  }

  .modal-footer {
    p {
      padding: 0;
    }
  }
}

esk-bien-details {
  .container-fullw {
    .details-title {
      .title-lock {
        color: app_variables.$light05Azure;
      }
    }

    .container {
      .details-short {
        > * {
          margin-right: 5px;
        }
      }

      .properties-description {
        background-color: variables.$whiteSmoke;
        margin-top: 30px;
        margin-right: -15px;
        margin-left: -15px;

        .description-title {
          background-color: white;
          border-top: app_variables.$border-separator-dashed;
          border-left: app_variables.$border-separator-dashed;
          border-right: app_variables.$border-separator-dashed;
          padding: 10px 10px;
          color: variables.$primary;
        }

        .description-details {
          padding: 0 20px 10px;

          table {
            th {
              font-weight: normal;
            }

            tr {
              font-weight: bold;

              td {
                p {
                  min-height: 18px;
                }
              }
            }
          }

          ul {
            margin-bottom: 0;

            li {
              border: 0;
              border-radius: 0;
              background-color: transparent;

              .details-commodite {
                border: solid 1px app_variables.$darkYellow;
                overflow: hidden;
                font-size: app_variables.$font-size-large;
                margin-top: 15px;

                .commodite-distance {
                  background-color: app_variables.$darkYellow;
                  line-height: 3;
                  text-align: center;
                  color: white;
                }

                .commodite-label {
                  line-height: 3;
                  padding: 0 20px;
                }

                .commodite-comments {
                  border-top: inherit;
                  font-size: variables.$font-size-base;
                  padding: 10px 10px 0;
                }
              }
            }
          }

          .text-bold {
            color: app_variables.$textDark;
          }

          .details-item {
            margin-top: 30px;

            h3 {
              font-weight: bold;
            }

            p[ng-bind-html] {
              text-align: justify;
              white-space: pre-wrap;
              font-weight: bold;
            }
          }

          .details-item:first-child {
            margin-top: 0;
          }
        }

        .description-virtual_visit {
          background-color: white;
          border: 1px solid variables.$primary;
          color: variables.$primary;
        }

        .description-price {
          &.vente {
            .description-details {
              .details-item {
                .text-right {
                  border-right: variables.$border-separator;
                }
              }
            }
          }

          &.location,
          &.estimation {
            .description-details {
              .details-item {
                .item-price {
                  > * {
                    display: inline-block;
                  }
                }
              }
            }
          }
        }
      }

      .properties-description:first-child {
        margin-top: 0;
      }
    }

    .action {
      .btn-group {
        .dropdown-menu {
          left: auto;
          right: 0;
          margin-bottom: 2px;
        }
      }

      .inline-nav {
        display: inline-block;
      }
    }

    .footer-action {
      .action {
        padding-top: 30px;

        .btn-group {
          .caret {
            border-top: 0;
            border-bottom: 4px dashed app_variables.$azure;
          }

          .dropdown-menu {
            top: auto;
            bottom: 100%;
          }
        }
      }
    }

    esk-button-print {
      .btn-group {
        .btn {
          font-size: app_variables.$font-size-large;
          background: none;
          border: 1px solid app_variables.$light05Azure;
          color: app_variables.$light05Azure;
        }
      }
    }
  }
}

@media (min-width: app_variables.$screen-sm-min) {
  esk-bien-details .container-fullw .container .property-col-left {
    padding-left: 10px;
    padding-right: 30px;
  }
}

@media (max-width: app_variables.$screen-sm-max) {
  esk-bien-details {
    .container-fullw {
      .container {
        .properties-description {
          .description-details {
            padding: 0 10px 10px;
          }
        }
      }
    }
  }
}

@media (max-width: app_variables.$screen-xs-max) {
  esk-bien-details {
    .container-fullw {
      .container {
        .properties-description {
          .description-details {
            padding: 0 10px 10px;
          }

          .description-price {
            &.vente {
              .description-details {
                .col-sm-6 {
                  text-align: center;
                }

                .details-item {
                  .text-right {
                    border-right: 0;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

.box-biens-activity {
  table {
    margin-bottom: 0;
    padding: 0 15px;

    tbody {
      tr:last-child {
        td {
          border-bottom: none;
        }
      }
    }
  }
}

.bien-edit {
  .fiche-link {
    text-align: right;
    padding-bottom: 15px;
  }

  .fiche-link,
  .tabs-container {
    display: inline-block;

    @media (max-width: app_variables.$screen-xs-max) {
      width: 100%;
      display: block;
    }
  }

  .field-constraint {
    label:first-child:after,
    > a:after {
      content: ' *';
    }
  }

  .edit-diagnostic .diagnostic-data .data-letter {
    position: relative;

    esk-select-dictionary {
      display: block;
      max-width: 100px;
    }

    a.btn {
      position: absolute;
      top: 0;
      left: 120px;
    }

    .popover {
      width: 250px;
    }
  }
}

[appProvideParentForm] .surface-cadastrale {
  label {
    height: 18px;

    .surface-cadastrale-operate {
      padding: 0 5px;
    }
  }
}

.dossier-biens-search {
  .actions {
    .action-search {
      .preparing {
        color: variables.$green;
      }
    }
  }
}
